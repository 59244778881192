import React from 'react'
import resolveLink from 'src/utils/resolveLink'

const Share = ({currentUrl, title}) => {
    const url = resolveLink(currentUrl)
    return (
        <div className="lg:absolute lg:h-[calc(100%-240px)] lg:top-48 lg:right-20">
            <div className="py-12 lg:sticky lg:top-32 lg:bottom-32">
                <div className="max-w-7xl mx-auto px-6">
                    <div className="flex lg:flex-col flex-row lg:items-start items-center lg:space-y-1 lg:space-x-0 space-x-4 text-base">
                        <h4 className="font-bold text-sm tracking-widest uppercase inline-block">Share:</h4>
                        <a
                            href={`http://www.facebook.com/sharer.php?u=https://generationmedia.co.uk${url}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i class="fa-brands fa-facebook hover:text-orange duration-300"></i>
                        </a>
                        <a
                            href={`https://twitter.com/share?url=https://generationmedia.co.uk${url}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i class="fa-brands fa-twitter hover:text-orange duration-300"></i>
                        </a>
                        <a
                            href={`https://www.linkedin.com/sharing/share-offsite/?url=https://generationmedia.co.uk${url}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i class="fa-brands fa-linkedin-in hover:text-orange duration-300"></i>
                        </a>
                        <a
                            href={`mailto:?subject=$&body=Check out this article on 主播大秀 Media: https://generationmedia.co.uk${url}`}
                        >
                            <i class="fa-regular fa-envelope hover:text-orange duration-300"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Share